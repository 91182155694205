<template>
  <v-dialog v-model="modalData.dialog" max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <!-- <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
        New Item
      </v-btn> -->
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="9" md="9">
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  :label="$t('FORMS.country')"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  v-model="formModel.id"
                  :label="$t('FORMS.id')"
                  disabled
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.locale"
                  :items="languages"
                  item-text="name"
                  item-value="lang"
                  :label="$t('FORMS.language')"
                  :rules="localeRules"
                  :id="dynamicID"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.currency_id"
                  :items="currencyCollection"
                  item-text="sign"
                  item-value="id"
                  :label="$t('FORMS.currency')"
                  :rules="currencyRules"
                  :id="dynamicID"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-checkbox v-model="formModel.eu" label="EU"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-checkbox
                  v-model="formModel.status"
                  :label="$t('FORMS.active')"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <!-- <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            {{ $t("FORMS.cancel") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="handleSaveModalForm">
            {{ $t("FORMS.save") }}
          </v-btn>
        </v-card-actions> -->
        <v-card-actions class="mb-2">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseModalForm">
            {{ $t("FORMS.cancel") }}
          </v-btn>
          <v-btn
            v-if="
              (permissionCan('create') && !formModel.id) ||
              permissionCan('update')
            "
            color="primary"
            elevation="2"
            @click="handleSaveModalForm"
          >
            {{ $t("FORMS.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import {
  FETCH_CURRENCY,
  CURRENCY_COLLECTION,
} from "@/core/services/store/currency.module.js";

const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = {
  id: "",
  locale: "",
  eu: 1,
  currency_id: "EUR",
  translations: {},
};

export default {
  name: "CountryForm",
  props: ["modalData", "permissions"],
  data() {
    return {
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      formModel: Object.assign({}, initialFormData),
      formValid: false,
      nameRules: [
        (v) => !!v || this.$t("ERRORS.field_is_required"),
        (v) =>
          (!!v && v.length < 250) || this.$t("ERRORS.must_be_max_characters"),
      ],
      requiredRules: [(v) => !!v || this.$t("ERRORS.field_is_required")],
      localeRules: [(v) => !!v || this.$t("ERRORS.language_is_required")],
      currencyRules: [(v) => !!v || this.$t("ERRORS.currency_is_required")],
    };
  },

  computed: {
    ...mapGetters([CURRENCY_COLLECTION]),
    dynamicID() {
      // return 'dynamicID-' + Math.floor(Math.random() * Date.now().toString());

      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
    formTitle() {
      return this.modalData.editedIndex === -1
        ? this.$t("MENU.NEW")
        : this.$t("FORMS.edit") +
            this.$helpers.getTranslated(this.formModel.translations).name;
    },
  },
  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.selectedLocale = i18nService.languages.find((item) => {
          return item.lang == i18nService.getActiveLanguage();
        });
        this.formModel = value.editedItem;
        this.setTranslatedAttributes();
      },
    },
  },
  methods: {
    ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm() {
      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
      }
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == "system.country." + action);
    },

    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },
  },
  async mounted() {
    this.fetchCurrency();
  },
};
</script>
